* {
	margin: 0;
	box-sizing: border-box;
}

html, body, #root {
	height: 100%;
	padding: 0;
}

body {
	font-size: 0.875rem;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1.43;
	letter-spacing: 0.01071em;
}

#app-root {
	display: flex;
	padding-top: 64px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.MuiPaper-root {
	background-color: #fff;
}